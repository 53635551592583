* {
  margin: 0;
  padding: 0;
  border: 0;
  scroll-behavior: smooth;
}

body {
  scroll-behavior: smooth;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-display: swap;
  background-color: #fafafa;
  height: auto;
}

a {
  text-decoration: none;
}

img {
  width: inherit;
}

input,
textarea,
button {
  border: none;
  padding: 0;
}

::-webkit-scrollbar {
  width: 3px;
}

::-webkit-scrollbar-thumb {
  background-color: var(--blue);
  border-radius: 2px;
}

button {
  cursor: pointer;
}
:root {
  --Green-900: #006d40;
  --Green-300: #4d9476;
  --Yellow: #fafaaf;
  --black: #2f3947;
  --gray: #7e7e7e;
  --white: #fafafa;
}

h1 {
  font-size: 2.8rem;
}

h2 {
  font-size: 2.6rem;
}

h3 {
  font-size: 2.4rem;
}

h4,
summary,
p {
  font-size: 1.5rem;
}

@media screen and (max-width: 1220px) {
  h1 {
    font-size: 2.6rem;
  }

  h2 {
    font-size: 2.4rem;
  }

  h3 {
    font-size: 2.2rem;
  }

  h4,
  summary,
  p {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 1000px) {
  h1 {
    font-size: 2.5rem;
  }

  h2 {
    font-size: 2.3rem;
  }

  h3 {
    font-size: 2.1rem;
  }

  h4,
  summary,
  p {
    font-size: 1.4rem;
  }
}
@media screen and (max-width: 600px) {
  h1 {
    font-size: 1.8rem;
  }

  h2 {
    font-size: 1.6rem;
  }

  h3 {
    font-size: 1.2rem;
  }

  h4,
  summary,
  p {
    font-size: 1.3rem;
  }
}

@media screen and (max-width: 320px) {
  h1 {
    font-size: 1.5rem;
  }

  h2 {
    font-size: 1.3rem;
  }

  h3 {
    font-size: 1.2rem;
  }

  h4,
  summary,
  p {
    font-size: 1.1rem;
  }
}
a {
  cursor: pointer;
}

button {
  cursor: pointer;
}
